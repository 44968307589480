import React, { useState, useEffect } from 'react'; 
import './App.css';

function App() {
  const [mural, setMural] = useState([]);
  useEffect(() => {
    for (let i =0; i <= 20; i++) {
      const options = {method: 'GET', mode: 'cors'};
      fetch(`https://api.opensea.io/api/v1/assets?asset_contract_address=0xfa12fae65134a8f2041a68b88a253d23e7914804&offset=${i*50}&limit=50`, options)
      // fetch(`https://api.opensea.io/api/v1/asset/0xfa12fae65134a8f2041a68b88a253d23e7914804/${i}/`, options)
      .then(response => response.json())
      .then(response => {
        response.assets.forEach(boncuk => {
          mural.push({image: boncuk.image_url, link: boncuk.permalink});
          setMural([...mural]);  
        })
      })
      .catch(err => console.error(err));
    }
  }, []);
  return (
    <div className="App">
      <header className="App-header" style={{}}>
        {mural.map((boncuk) => boncuk.image && <a href={boncuk.link} target="_blank"><img className="fade-in" key={boncuk.permalink} src={boncuk.image} /></a>)}
      </header>
    </div>
  );
}

export default App;
